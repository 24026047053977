// some hacky shared-password functionality

let apiPassword: string | null = null;

export function storePassword(password: string) {
  window.localStorage.setItem('jakeSharedPassword', password);
  //apiPassword = password;
}

export function getPassword() {
  return window.localStorage.getItem('jakeSharedPassword');
  //return apiPassword;
}

export function clearPassword() {
  window.localStorage.removeItem("jakeSharedPassword");
  //apiPassword = null;
}

export function getAPITokenHeaders() {
  return new Headers({
    'Authorization': 'Bearer ' + getPassword()
  });
}
