import React from 'react';
import './App.css';
import PolicyEditor from './PolicyEditor';

import { APIDictionary, GetDataDictionary, NewPolicy } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import PolicyList from './PolicyList';
import RegistrantFactsEditor from './RegistrantFactsEditor';
import RegistrantFactsList from './RegistrantFactsList';
import _ from 'lodash';
import ReactModal from 'react-modal';
import { clearPassword, getAPITokenHeaders, getPassword, storePassword } from './password';

// mode includes the selected object ID so that we can set the state once and
// have a guarantee that we won't e.g. set the selected object ID without
// changing the mode
type AppMode = {
  mode: "home";
} | {
  mode: "policy";
  policyID: number;
} | {
  mode: "registrant_facts";
  registrantFactsID: number | null;
};

function App() {
  const [apiPassword, setApiPassword] = React.useState<string | null>(null);
  const [mode, setMode] = React.useState<AppMode>({ mode: "home" });
  const [dataDictionary, setDataDictionary] = React.useState<APIDictionary | null>(null);
  const [isLoadingDD, setIsLoadingDD] = React.useState<boolean>(false);
  const [isCreatingPolicy, setIsCreatingPolicy] = React.useState<boolean>(false);

  if (getPassword() === null) {
    return (
      <ReactModal
        isOpen={true}>
        <div style={{ textAlign: "center" }}>
          Please enter password:<br />
          <input type="password"
            id="api-password-input"
            autoFocus={true}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                const password = (document.getElementById("api-password-input") as HTMLInputElement).value;
                storePassword(password);
                setApiPassword(password);
              }
            }}
          />
        </div>
      </ReactModal>
    );
  }

  if (dataDictionary === null && !isLoadingDD) {
    setIsLoadingDD(true);
    getDataDictionary().then(setDataDictionary)
      .catch(e => alert(e))
      .finally(() => setIsLoadingDD(false));
    return (<LoadingSpinner />);
  }

  // first check if user navigated to #/policy/<id>
  function checkHashChange() {
    const newAppMode = getAppModeFromHash();
    if (!_.isEqual(newAppMode, mode)) {
      setMode(newAppMode);
    }
  }
  window.onhashchange = checkHashChange;
  checkHashChange();

  if (mode.mode === 'policy') {
    return (
      <div className="App">
        {dataDictionary === null ?
          <LoadingSpinner /> :
          <PolicyEditor policyID={mode.policyID} dataDictionary={dataDictionary} />
        }
      </div>
    );
  }

  if (mode.mode === 'registrant_facts') {
    return (
      <div className="App">
        {dataDictionary === null ?
          <LoadingSpinner /> :
          <RegistrantFactsEditor
            dataDictionary={dataDictionary}
            selectedRegistrantFactsID={mode.registrantFactsID}
          />
        }
      </div>
    );
  }

  return (
    <div className="App">
      <h2>Policy Expressions (PEX)</h2>
      <button
        disabled={isCreatingPolicy}
        onClick={
          () => {
            setIsCreatingPolicy(true);
            createNewPolicy().then((newPolicyID) => {
              window.location.hash = `/policy/${newPolicyID}`;
              setMode({ mode: "policy", policyID: newPolicyID });
            }).finally(() => setIsCreatingPolicy(false));
          }
        }>Create New Policy</button>
      <PolicyList onSelectPolicy={(newPolicyID) => {
        // @ts-ignore
        window.location.hash = `/policy/${newPolicyID}`
        setMode({
          mode: "policy",
          policyID: newPolicyID
        });
      }} />
    </div>
  );
}

// make a XHR to /api/data_dictionary
async function getDataDictionary(): Promise<APIDictionary> {
  const rawResponse = await fetch('/api/data_dictionary', {
    headers: getAPITokenHeaders()
  });
  if (rawResponse.status === 401) {
    clearPassword();
    throw new Error("Incorrect password");
  }
  const response = await rawResponse.json() as unknown as GetDataDictionary;
  if (response.status === 'success') {
    return response.data;
  } else {
    throw new Error(response.error);
  }
}

// returns the ID number of the newly created policy
function createNewPolicy(): Promise<number> {
  return new Promise((resolve, reject) => {
    fetch('/api/policy', {
      method: 'PUT',
      headers: getAPITokenHeaders()
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as NewPolicy;
        if (response.status === 'success') {
          return resolve(response.id);
        } else {
          return reject(response.error);
        }
      })
      .catch(error => reject(error));
  });
}

function getAppModeFromHash(): AppMode {
  let match: RegExpMatchArray | null;
  if (match = window.location.hash.match(/\/policy\/(\d+)$/)) {
    return {
      mode: "policy",
      policyID: parseInt(match[1]),
    };
  }
  if (match = window.location.hash.match(/\/registrant_facts\/(\d+)$/)) {
    return {
      mode: "registrant_facts",
      registrantFactsID: parseInt(match[1]),
    };
  }
  return { mode: "home" };
}

ReactModal.defaultStyles.overlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
};

ReactModal.defaultStyles.content = {
  position: 'absolute',
  top: '20%',
  left: '40px',
  right: '40px',
  maxHeight: '80vh',
  border: '1px solid #ccc',
  background: '#fff',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  borderRadius: '4px',
  outline: 'none',
  padding: '20px'
};

export default App;
