import React from 'react';
import _ from 'lodash';
import ReactModal from 'react-modal';

import { APIAttribute, APIDictionary, APIElement, APIElementGroup, APIError, APIPolicy, APIRule, APIRuleAttribute, APISuccess, GetPolicy, NewPolicy, PolicyListItem } from './common/api';

import { getAttributeFromRule, getRuleForElement, ORG_TYPE_ABBR } from './common/util';
import EditableLabel from './EditableLabel2';
import LoadingSpinner from './LoadingSpinner';
import MultiSelectPolicyList from './MultiSelectPolicyList';
import Scope from './Scope';
import AttributeSelect from './AttributeSelect';
import CollapsibleBox from './CollapsibleBox';
import { getAPITokenHeaders } from './password';
import Note from './Note';
import { getPolicyMetadata } from './policyData';

type ComparisonResult = "unknown" | "equal" | "subset" | "superset" | "incompatible";

interface PolicyEditorProps {
  policyID: number;
  dataDictionary: APIDictionary;
}

interface PolicyEditorState {
  policy: APIPolicy | null;
  isLoadingPolicy: boolean;
  isDirty: boolean;
  isSaving: boolean;

  selectPolicyModal: boolean;
  selectedComparisonPolicies: number[];
  comparisonPolicy: APIPolicy | null;
  policyLoadingProgress: number | null | "unknown";
  editSubjectToModal: boolean;
  // rather than keep a list of which subject-to policies are selected for comparison,
  // keep a list of policies that are DEselected so that they default to selected.
  ignoredSubjectToPolicies: number[];
  // TODO: is it safe to use number keys
  policyMetadataCache: { [key: number]: PolicyListItem };

  rowSelectRange: [number, number] | null;
  copiedData: APIRule[] | null;
  pasteSpecialModal: boolean;
  pasteOnly: number[]; // attribute IDs to paste
}

/*
const WRAPPER_FIELDS: keyof APIPolicy = [
  "org_name", "org_type",
  "prime_poc", "prime_email",
  "alt_poc", "alt_email",
  "effective_date"
];
*/

export default class PolicyEditor extends React.Component<PolicyEditorProps, PolicyEditorState> {

  // intercept hash change to give user a chance to save their work
  private oldOnhashchange?: typeof window.onhashchange;

  constructor(props: PolicyEditorProps) {
    super(props);
    this.state = {
      policy: null,
      isLoadingPolicy: true,
      isDirty: false,
      isSaving: false,
      selectPolicyModal: false,
      editSubjectToModal: false,
      selectedComparisonPolicies: [],
      comparisonPolicy: null,
      policyLoadingProgress: null,
      ignoredSubjectToPolicies: [],
      // TODO: add policy names
      policyMetadataCache: {},
      rowSelectRange: null,
      copiedData: null,
      pasteSpecialModal: false,
      pasteOnly: this.props.dataDictionary.rule_attributes.map(att => att.id),
    };

    this.fetchPolicy();

    window.onbeforeunload = (e) => {
      if (this.state.isDirty) {
        e.preventDefault();
        e.returnValue = '';
        return "There are unsaved changes. Are you sure you want to leave?";
      } else {
        return undefined;
      }
    }

    // to handle "navigating away" where the hash changes but onbeforeunload is
    // not triggered, we intercept the original onhashchange handler to first
    // ask the user if they want to save their work and restore the current hash
    // if the user elects to stay on the current page.
    // in development mode, React (StrictMode) renders twice, so don't do this work the first time
    // @ts-ignore
    if (!console.log.__reactDisabledLog) {
      // first store the old onhashchange so that we can restore it when this component unmounts
      console.log("Storing ", window.onhashchange?.toString(), " as oldOnhashchange");
      this.oldOnhashchange = window.onhashchange;

      console.log("Setting policyEditor's onhashchange handler")
      // @ts-ignore
      window.onhashchange = (e: HashChangeEvent) => {
        // if the new url is the same as the current policy, then do nothing
        if (this.state.policy && getURLHash(e.newURL) == `/policy/${this.state.policy.id}`) {
          return;
        }
        if (!this.state.isDirty || window.confirm("You may have unsaved changes. Are you sure you want to leave?")) {
          this.oldOnhashchange?.call(window, e);
        } else {
          window.location.hash = getURLHash(e.oldURL);
          e.preventDefault();
        }
      };
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    console.log("Setting old window.onhashchange to ", this.oldOnhashchange?.toString());
    window.onhashchange = this.oldOnhashchange ?? null;
  }

  fetchPolicy() {
    this.setState({ isLoadingPolicy: true });
    getPolicy(this.props.policyID).then((newPolicy) => {
      this.setState({ policy: newPolicy });
      getPolicyMetadata(newPolicy.subject_to).then((newMetadata) =>
        this.setState({ policyMetadataCache: mapPolicyIDsToName(newMetadata) })
      );
    })
      .finally(() => {
        this.setState({ isLoadingPolicy: false, isDirty: false });
      });
  }

  componentDidUpdate(prevProps: PolicyEditorProps, prevState: PolicyEditorState) {
    if (this.props.policyID !== prevProps.policyID) {
      this.fetchPolicy();
    }
  }

  render() {
    const props = this.props; // for compatibility with old functional component code
    //const [policy, setPolicy] = React.useState<APIPolicy | null>(null);
    const policy = this.state.policy;
    //const [isLoadingPolicy, setIsLoadingPolicy] = React.useState<boolean>(false);
    const isLoadingPolicy = this.state.isLoadingPolicy;
    //const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const isDirty = this.state.isDirty;
    //const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const isSaving = this.state.isSaving;

    //const [selectPolicyModal, setSelectPolicyModal] = React.useState<boolean>(false);
    const selectPolicyModal = this.state.selectPolicyModal;
    //const [selectedComparisonPolicies, setSelectedComparisonPolicies] = React.useState<number[]>([]);
    const selectedComparisonPolicies = this.state.selectedComparisonPolicies;
    //const [comparisonPolicy, setComparisonPolicy] = React.useState<APIPolicy | null>(null);
    const comparisonPolicy = this.state.comparisonPolicy;
    //const [policyLoadingProgress, setPolicyLoadingProgress] = React.useState<number | null | "unknown">(null);
    const policyLoadingProgress = this.state.policyLoadingProgress;

    //const [rowSelectRange, setRowSelectRange] = React.useState<[number, number] | null>(null);
    const rowSelectRange = this.state.rowSelectRange;

    // the copied data is a list of APIRule, but for pasting attribute values we
    // can just ignore the id and element_id properties
    //const [copiedData, setCopiedData] = React.useState<APIRule[] | null>(null);

    // TODO: this doesn't work if you call it twice in a row because the value of
    // `policy` isn't updated until the next render, so the last write wins.
    // Avoid using it and use updatePolicy instead.
    const that = this;
    function updatePolicyField<T extends keyof APIPolicy>(field: T, value: APIPolicy[T]) {
      const newPolicy = Object.assign({}, policy);
      newPolicy[field] = value;
      that.setState({ policy: newPolicy, isDirty: true });
    }

    const dict = props.dataDictionary;

    function mapPolicyIDsToName(policies: PolicyListItem[]) {
      const mapping: { [key: number]: PolicyListItem } = {};
      policies.forEach(p => mapping[p.id] = p);
      return mapping;
    }

    if (policy === null) {
      return (<LoadingSpinner />);
    }

    // flatten the list of element groups into a list of [element_group, element]
    // pairs where element_group is null if the element is not the first in the
    // group
    const flattenedElements: [APIElementGroup | null, APIElement][] = flattenDictElements(dict);

    return (
      <div className={this.state.isLoadingPolicy ? "PolicyEditor loading" : "PolicyEditor"}>
        {
          // show loading spinner but don't get rid of the table so we don't
          // have to redraw everything from scratch when changing policies
          this.state.isLoadingPolicy ? (<LoadingSpinner />) : null
        }
        <div className="toolbar"
          style={{ marginBottom: "1em" }}
        >
          <button
            type="button" onClick={() => {
              window.location.hash = "";
            }}>
            <span className="material-icons-outlined" aria-hidden={true}>arrow_back</span>
            Home
          </button>
          <button
            disabled={!isDirty || isSaving}
            onClick={() => {
              this.setState({ isSaving: true });
              savePolicy(policy)
                .then(() => this.setState({ isDirty: false }))
                .finally(() => this.setState({ isSaving: false }));
            }}
          >
            <span className="material-icons-outlined" aria-hidden={true}>save</span>
            {isSaving ? "Saving..." : "Save changes"}
          </button>
          <button onClick={() => {
            this.setState({ selectPolicyModal: true, policyLoadingProgress: null });
          }}>
            <span className="material-icons-outlined" aria-hidden={true}>compare</span>
            Compare with...
          </button>
          <button onClick={() => {
            this.setState({ isLoadingPolicy: true });
            copyPolicy(policy.id).then((newPolicyID) =>
              window.location.hash = `/policy/${newPolicyID}`
            )
              .finally(() => this.setState({ isLoadingPolicy: false }));
          }}>
            <span className="material-icons-outlined" aria-hidden={true}>file_copy</span>
            Make copy of this policy
          </button>
          <button onClick={() => {
            this.setState({ isLoadingPolicy: true });
            deletePolicy(policy.id).then(() =>
              window.location.hash = '/')
              .finally(() => this.setState({ isLoadingPolicy: false }));
          }}>
            <span className="material-icons-outlined" aria-hidden={true}>delete</span>
            Delete this policy
          </button>
        </div>
        <div className="toolbar"
          style={{
            position: "fixed", bottom: "1em", left: "1em",
            zIndex: 1000,
            display: rowSelectRange ? "block" : "none",
          }}
        >
          <button onClick={() => this.copyData()}>
            <span className="material-icons-outlined" aria-hidden={true}>content_copy</span>
            Copy row values
          </button>
          <button disabled={this.state.copiedData === null} onClick={() => this.pasteData()}>
            <span className="material-icons-outlined" aria-hidden={true}>content_paste</span>
            {
              this.state.copiedData === null ?
                "Paste row values" :
                this.state.copiedData.length === 1 ?
                  "Duplicate 1 row across selection" :
                  `Paste ${this.state.copiedData.length} rows of values`
            }
          </button>
          <button disabled={this.state.copiedData === null} onClick={() => this.setState({ pasteSpecialModal: true })}>
            <span className="material-icons-outlined" aria-hidden={true}>content_paste</span>
            Paste only...
          </button>
        </div>
        <table
          className="PolicyEditor-table"
          style={{
            marginLeft: "auto",
            marginRight: "auto"
          }}>
          <thead>
            <tr>
              {/* Number of columns to span is number of different attributes plus:
                  * element group name
                  * element name
                  * notes column
               */}
              <td colSpan={3 + dict.rule_attributes.length}>
                <h1>
                  <EditableLabel
                    allowNewline={false}
                    value={policy.name}
                    onValueChange={(value) => {
                      if (value.trim() === '') {
                        value = "";
                      }
                      this.updatePolicy({
                        "name": value
                      });
                    }}
                  />
                </h1>
              </td>
              {
                comparisonPolicy ? (
                  <>
                    <td className="spacercol"></td>
                    <td rowSpan={2} colSpan={dict.rule_attributes.length}
                      style={{ width: "min-content" }}>
                      <h1>
                        <ul>
                          {comparisonPolicy.name.split(" ∩ ").map(
                            pn => <li key={pn}>{pn}</li>)}
                        </ul>
                      </h1>
                    </td>
                  </>
                ) : null
              }
            </tr>
            <tr>
              <td colSpan={3 + dict.rule_attributes.length}>
                <div className="policy-header-container"
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <CollapsibleBox
                    title="Wrapper"
                    style={{ flex: "1 1", height: "max-content" }}
                  >
                    <table
                      className="PolicyEditor-wrapper"
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <td>Organization Name</td>
                          <td>
                            <input value={policy.org_name}
                              type="text"
                              onChange={(e) =>
                                this.updatePolicy({
                                  org_name: e.target.value
                                })
                              } />
                          </td>
                        </tr>
                        <tr>
                          <td>Organization Type</td>
                          <td>
                            <select
                              value={policy.org_type}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue !== "registry" && newValue !== "registrar" && newValue !== "policy_authority") {
                                  return;
                                }
                                this.updatePolicy({
                                  org_type: newValue
                                })
                              }}
                            >
                              <option value="policy_authority">Policy Authority</option>
                              <option value="registry">Registry</option>
                              <option value="registrar">Registrar</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Prime PoC</td>
                          <td>
                            <input value={policy.prime_poc}
                              type="text"
                              onChange={(e) =>
                                updatePolicyField("prime_poc", e.target.value)
                              } />
                          </td>
                        </tr>
                        <tr>
                          <td>Prime email</td>
                          <td>
                            <input value={policy.prime_email}
                              type="text"
                              onChange={(e) =>
                                updatePolicyField("prime_email", e.target.value)
                              } />
                          </td>
                        </tr>
                        <tr>
                          <td>Alternate PoC</td>
                          <td>
                            <input value={policy.alt_poc}
                              type="text"
                              onChange={(e) =>
                                updatePolicyField("alt_poc", e.target.value)
                              } />
                          </td>
                        </tr>
                        <tr>
                          <td>Alternate email</td>
                          <td>
                            <input
                              type="text"
                              value={policy.alt_email}
                              onChange={(e) =>
                                updatePolicyField("alt_email", e.target.value)
                              } />
                          </td>
                        </tr>
                        <tr>
                          <td>Effective Date</td>
                          <td>
                            <input value={policy.effective_date} type="date"
                              onChange={(e) =>
                                updatePolicyField("effective_date", e.target.value)
                              } />
                          </td>
                        </tr>
                        <tr>
                          <td>Completion</td>
                          <td>
                            <select value={policy.completion}
                              onChange={(e) =>
                                updatePolicyField("completion", e.target.value === "final" ? "final" : "draft")
                              } >
                              <option value="draft">Draft</option>
                              <option value="final">Final</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>
                            <select value={policy.status}
                              onChange={(e) =>
                                // @ts-ignore
                                updatePolicyField("status", e.target.value)
                              } >
                              <option value="proposed">Proposed</option>
                              <option value="planned">Planned</option>
                              <option value="actual">Actual</option>
                              <option value="certified">Certified</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Version</td>
                          <td>
                            <span className="media-print">{policy.version}</span>
                            <select value={policy.id}
                              className="media-screen"
                              onChange={(e) => {
                                const selectedPolicyID = parseInt(e.target.value);
                                if (selectedPolicyID !== policy.id) {
                                  window.location.hash = `/policy/${selectedPolicyID}`;
                                }
                              }}
                            >
                              {
                                policy.versions.map(v => (
                                  <option key={v.id} value={v.id}>{v.version}</option>
                                ))
                              }
                            </select>
                            <button onClick={() => {
                              this.setState({ isLoadingPolicy: true });
                              newPolicyVersion(policy.id).then(async (newPolicyID) => {
                                updatePolicyField("version", policy.version + 1);
                                updatePolicyField("id", newPolicyID);
                                // Save the user's unsaved changes to the new version
                                // to prevent losing unsaved work
                                if (isDirty) {
                                  await savePolicy(Object.assign({}, policy, { id: newPolicyID }));
                                }
                                return newPolicyID;
                              })
                                .then((newPolicyID) => window.location.hash = `/policy/${newPolicyID}`)
                                .finally(() => this.setState({ isLoadingPolicy: false }));
                            }} style={{ float: "right" }}>
                              <span className="material-icons-outlined">file_copy</span>
                              Copy policy as new version
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Compare list</td>
                          <td>{
                            policy.subject_to.length === 0 ? (
                              <div><em>No other policies are specified for this policy to compare to.</em></div>
                            ) : (
                              <ul className="compare-list">
                                {policy.subject_to.map(pID => (
                                  this.state.policyMetadataCache[pID]?.deleted === true ?
                                    (
                                      <li key={pID}>
                                        <s>{formatPolicyListItemName(this.state.policyMetadataCache[pID])}</s>&nbsp;
                                        <button
                                          className="small-unlabeled"
                                          onClick={() => this.removeCompareListItem(pID)}
                                        >
                                          <span className="material-icons-outlined" aria-label="Remove">close</span>
                                        </button>
                                      </li>
                                    ) :
                                    (
                                      <li key={pID}>
                                        <input type="checkbox"
                                          checked={!this.state.ignoredSubjectToPolicies.includes(pID)}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              this.setState({
                                                ignoredSubjectToPolicies: this.state.ignoredSubjectToPolicies.filter(i => i !== pID)
                                              });
                                            } else {
                                              this.setState({
                                                ignoredSubjectToPolicies: this.state.ignoredSubjectToPolicies.concat([pID])
                                              });
                                            }
                                          }}
                                          className="media-screen"
                                        />
                                        <a href={`#/policy/${pID}`}>
                                          {
                                            this.state.policyMetadataCache[pID] ?
                                              formatPolicyListItemName(this.state.policyMetadataCache[pID]) :
                                              `<policy ${pID}>`
                                          }
                                        </a>
                                        &nbsp;
                                        <button
                                          className="small-unlabeled"
                                          onClick={() => this.removeCompareListItem(pID)}
                                        >
                                          <span className="material-icons-outlined" aria-label="Remove">close</span>
                                        </button>
                                      </li>
                                    )
                                ))}
                              </ul>
                            )
                          }
                            <button
                              onClick={() => this.setState({ editSubjectToModal: true })}
                            >
                              <span className="material-icons-outlined" aria-hidden={true}>edit</span>
                              Add/remove policies ...
                            </button>
                            <button
                              onClick={async () => {
                                const comparisonPolicy = await downloadComparisonPolicies(
                                  policy.subject_to.filter(p => !this.state.ignoredSubjectToPolicies.includes(p) &&
                                    this.state.policyMetadataCache[p]?.deleted !== true),
                                  (progress) => null,
                                  this.props.dataDictionary
                                );
                                this.setState({
                                  comparisonPolicy: comparisonPolicy
                                });
                              }}
                            >
                              <span className="material-icons-outlined" aria-hidden={true}>compare</span>
                              Compare
                            </button>
                            <ReactModal
                              isOpen={this.state.editSubjectToModal}
                              onRequestClose={() => this.setState({ editSubjectToModal: false })}
                              appElement={document.getElementById('root') ?? undefined}
                            >
                              <p>Compare <strong>{policy.name}</strong> with:</p>
                              <MultiSelectPolicyList onSelectPolicy={(newPolicy) => {
                                const policyID = newPolicy.id;
                                if (!this.state.policyMetadataCache[policyID]) {
                                  const newPolicyMetadataCache = Object.assign({}, this.state.policyMetadataCache);
                                  newPolicyMetadataCache[policyID] = newPolicy;
                                  this.setState({
                                    policyMetadataCache: newPolicyMetadataCache
                                  });
                                }
                                if (policy.subject_to.includes(policyID)) {
                                  this.removeCompareListItem(policyID);
                                } else {
                                  updatePolicyField("subject_to", policy.subject_to.concat([policyID]));
                                }
                              }} selectedPolicies={policy.subject_to} />
                              <div style={{ marginTop: "1em", textAlign: "right" }}>
                                <button onClick={() => this.setState({ editSubjectToModal: false })}>
                                  Done
                                </button>
                              </div>
                            </ReactModal>
                          </td>
                        </tr>
                        <tr>
                          <td>Notes</td>
                          <td>
                            <textarea
                              style={{ width: "95%", height: "6.2em" }}
                              value={policy.notes || "Lorem ipsum dolor sit amet, consectetur adip"}
                              onChange={(e) => updatePolicyField("notes", e.target.value)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CollapsibleBox>
                  <CollapsibleBox
                    style={{ flex: "1 1", height: "max-content", width: "min-content" }}
                    title="Scope"
                  >
                    <Scope
                      scopeAttributes={policy.scope_attributes}
                      dataDictionary={props.dataDictionary}
                      tldList={policy.tlds}
                      onAttributeChange={(attID, newVal) => {
                        const newScopeAtts = policy.scope_attributes.slice();
                        const existingAttIndex = newScopeAtts.findIndex(att => att.attribute_id === attID);
                        // if this attribute doesn't have a value yet, add it to the list
                        if (existingAttIndex === -1) {
                          newScopeAtts.push({ attribute_id: attID, value: newVal });
                        } else { // otherwise update the existing attribute
                          newScopeAtts[existingAttIndex] = Object.assign({}, newScopeAtts[existingAttIndex], { value: newVal });
                        }
                        updatePolicyField("scope_attributes", newScopeAtts);
                      }}
                      onTLDListChange={(newTLDList) => {
                        this.updatePolicy({
                          tlds: newTLDList,
                          tld_list_id: newTLDList.id
                        });
                      }}
                    />
                  </CollapsibleBox>
                </div>
              </td>
            </tr>
            <tr>
              <th>Group</th>
              <th>Element</th>
              {
                dict.rule_attributes.map(att => (<th key={att.id} colSpan={1}>{att.name}</th>))
              }
              <th><span className="material-icons-outlined" title="Notes">sticky_note_2</span></th>
              {
                // if we are comparing against another policy, show it side by side
                comparisonPolicy ? (
                  <>
                    <th style={{ backgroundColor: "white" }} className="spacercol">&rarr;</th>
                    {
                      dict.rule_attributes.map(att => (<th key={`comp-${att.id}`} colSpan={1}>{att.name}</th>))
                    }
                  </>
                ) : null
              }
            </tr>
          </thead>
          <tbody>
            {
              flattenedElements.map((item, i) => {
                const eg = item[0];
                const el = item[1];
                let rule = getRuleForElement(policy, el.id);
                if (!rule) {
                  rule = {
                    id: 0,
                    element_id: el.id,
                    attributes: []
                  };
                }
                // undefined means we're not comparing, null means we're comparing but we're missing a rule
                const comparisonRule = comparisonPolicy ? getRuleForElement(comparisonPolicy, el.id) ?? null : undefined;
                let group = eg !== null ? { rows: eg.elements.length, name: eg.name } : undefined;
                return (
                  <PolicyRule
                    key={el.id}
                    element={el}
                    dictionary={dict}
                    rule={rule}
                    comparisonRule={comparisonRule}
                    group={group}
                    selected={rowSelectRange !== null && isWithinRange(i, rowSelectRange[0], rowSelectRange[1])}
                    onSelectBegin={() => this.setState({ rowSelectRange: [i, i] })}
                    onSelectEnd={() => {
                      if (!rowSelectRange) { return; }
                      this.setState({ rowSelectRange: [rowSelectRange[0], i] });
                    }}
                    onSelectClear={() =>
                      this.setState({ rowSelectRange: null })
                    }
                    onChange={(elementID, attributeID, newVal) => {
                      this.setState({
                        policy: updatePolicyRuleAttributeValue(policy, elementID, attributeID, newVal),
                        isDirty: true,
                      });
                    }}
                    onNoteChange={(newNote) => this.setState({
                      policy: updatePolicyRuleField(policy, el.id, "notes", newNote),
                      isDirty: true
                    })}
                  />
                );
              })
            }
          </tbody>
        </table>
        <div className="media-print" style={{maxWidth:"100vw"}}>
          {
            this.props.dataDictionary.element_groups.map(eg =>
              eg.elements.map((el, i) => {
                let rule = getRuleForElement(policy, el.id);
                if (rule && rule.notes) {
                  return (
                    <p key={el.id}><strong>{eg.name}&mdash;{el.name}:</strong> {rule.notes}</p>
                  )
                } else {
                  return null;
                }
              })
            )
          }
        </div>
        <ReactModal
          isOpen={selectPolicyModal}
          onRequestClose={() => this.setState({ selectPolicyModal: false })}
          appElement={document.getElementById('root') ?? undefined}
        >
          <p>Compare <strong>{policy.name}</strong> with:</p>
          <MultiSelectPolicyList onSelectPolicy={(newPolicy) => {
            const policyID = newPolicy.id;
            if (selectedComparisonPolicies.includes(policyID)) {
              this.setState({
                selectedComparisonPolicies: selectedComparisonPolicies.filter(p => p !== policyID)
              });
            } else {
              this.setState({
                selectedComparisonPolicies: selectedComparisonPolicies.concat(policyID)
              });
            }
          }} selectedPolicies={selectedComparisonPolicies} />
          <p>
            {policyLoadingProgress === null ? (
              <button
                onClick={async () => {
                  this.setState({ policyLoadingProgress: 0 });
                  const comparisonPolicy = await downloadComparisonPolicies(
                    selectedComparisonPolicies,
                    (progress) => this.setState({ policyLoadingProgress: progress }),
                    props.dataDictionary);
                  this.setState({ policyLoadingProgress: "unknown" });
                  // delay re-rendering with comparisonPolicy so that browser can
                  // redraw the loading spinner to show that it's loading.
                  // Otherwise the loading spinner doesn't render before browser
                  // blocks on rerendering.
                  setTimeout(() =>
                    this.setState({
                      comparisonPolicy: comparisonPolicy,
                      policyLoadingProgress: null,
                      selectPolicyModal: false
                    }), 50);
                }}
              >Compare against selected policies</button>
            ) : policyLoadingProgress !== "unknown" ? (
              <progress
                style={{ width: "100%" }}
                max="100" value={policyLoadingProgress} />
            ) : <LoadingSpinner />
            }
          </p>
        </ReactModal>
        <ReactModal
          isOpen={this.state.pasteSpecialModal}
          onRequestClose={() => this.setState({ pasteSpecialModal: false })}
          appElement={document.getElementById('root') ?? undefined}>
            <p>Paste only:
              <ul>
                {
                  this.props.dataDictionary.rule_attributes.map(att => (
                    <li>
                      <input type="checkbox" checked={this.state.pasteOnly.includes(att.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            this.setState({ pasteOnly: this.state.pasteOnly.concat([att.id])});
                          } else {
                            this.setState({ pasteOnly: this.state.pasteOnly.filter(attID => attID !== att.id)});
                          }
                        }}
                      /> {att.name}
                    </li>
                  ))
                }
              </ul>
            </p>
            <p style={{textAlign: "right"}}>
              <button onClick={() => {this.pasteSpecial(); this.setState({ pasteSpecialModal: false })}}>
                <span className="material-icons-outlined" aria-hidden={true}>content_paste</span>
                Paste
              </button>
            </p>
        </ReactModal>
      </div>
    );
  }

  removeCompareListItem(policyID: number) {
    if (this.state.policy === null) { return; }
    this.updatePolicy({
      subject_to: this.state.policy.subject_to.filter(i => i !== policyID),
    });
  }

  updatePolicy(newValues: Partial<APIPolicy>) {
    if (this.state.policy === null) { return; }
    const newPolicy = Object.assign({}, this.state.policy, newValues);
    this.setState({ policy: newPolicy, isDirty: true });
  }

  copyData() {
    const selectRange = this.state.rowSelectRange;
    const policy = this.state.policy;
    if (!selectRange || !policy) { return; }

    let min: number, max: number;
    if (selectRange[0] > selectRange[1]) {
      min = selectRange[1];
      max = selectRange[0];
    } else {
      min = selectRange[0];
      max = selectRange[1];
    }

    // the table is laid out based on the same flattened dictionary, so the row indexes should match up
    const flattenedElements = flattenDictElements(this.props.dataDictionary);
    const selectedElements = flattenedElements.slice(min, max + 1);

    const copiedRules = selectedElements.map(item =>
      getRuleForElement(policy, item[1].id) ?? {
        id: 0, element_id: item[1].id, attributes: []
      }
    );
    this.setState({ copiedData: copiedRules });
  }

  pasteData() {
    const selectRange = this.state.rowSelectRange;
    let policy = this.state.policy;
    const copiedData = this.state.copiedData;
    if (!selectRange || !policy || !copiedData) { return; }

    const flattenedElements = flattenDictElements(this.props.dataDictionary);

    // two cases: we are repeating one row many times, or copying many rows starting from a particular index
    // 1) repeat one row many times
    if (copiedData.length === 1) {
      let min: number, max: number;
      if (selectRange[0] > selectRange[1]) {
        min = selectRange[1];
        max = selectRange[0];
      } else {
        min = selectRange[0];
        max = selectRange[1];
      }
      const copyElement = copiedData[0];
      for (let i = min; i <= max; i++) {
        const element = flattenedElements[i][1];
        policy = updatePolicyRuleAttributes(policy, element.id, copyElement.attributes);
      }
    } else { // 2) copy a block of data beginning at specific element
      const min = Math.min(selectRange[0], selectRange[1]);
      const targetElements = flattenedElements.slice(min, min + copiedData.length);
      for (let i = 0; i < copiedData.length; i++) {
        if (i >= targetElements.length) { break; } // don't go past the end of the table
        policy = updatePolicyRuleAttributes(policy, targetElements[i][1].id, copiedData[i].attributes);
      }
    }
    this.setState({ policy });
  }

  // pasteSpecial lets the user choose which columns to paste instead of pasting all of them
  pasteSpecial() {
    const selectRange = this.state.rowSelectRange;
    let policy = this.state.policy;
    const copiedData = this.state.copiedData;
    if (!selectRange || !policy || !copiedData) { return; }

    const flattenedElements = flattenDictElements(this.props.dataDictionary);

    // two cases: we are repeating one row many times, or copying many rows starting from a particular index
    // 1) repeat one row many times
    if (copiedData.length === 1) {
      let min: number, max: number;
      if (selectRange[0] > selectRange[1]) {
        min = selectRange[1];
        max = selectRange[0];
      } else {
        min = selectRange[0];
        max = selectRange[1];
      }
      const copyElement = copiedData[0];
      for (let i = min; i <= max; i++) {
        const element = flattenedElements[i][1];
        const existingData = getRuleForElement(policy, element.id);
        policy = updatePolicyRuleAttributes(policy, element.id,
          mergeAttributes(existingData?.attributes ?? [], copyElement.attributes, this.state.pasteOnly)
        );
      }
    } else { // 2) copy a block of data beginning at specific element
      const min = Math.min(selectRange[0], selectRange[1]);
      const targetElements = flattenedElements.slice(min, min + copiedData.length);
      for (let i = 0; i < copiedData.length; i++) {
        if (i >= targetElements.length) { break; } // don't go past the end of the table
        const existingData = getRuleForElement(policy, targetElements[i][1].id);
        policy = updatePolicyRuleAttributes(policy, targetElements[i][1].id,
          mergeAttributes(existingData?.attributes ?? [], copiedData[i].attributes, this.state.pasteOnly)
        );
      }
    }
    this.setState({ policy });
  }
}

function flattenDictElements(dict: APIDictionary) {
  const flattenedElements: [APIElementGroup | null, APIElement][] = [];
  dict.element_groups.forEach(eg => (
    eg.elements.forEach((e, i) => {
      if (i === 0) {
        flattenedElements.push([eg, e]);
      } else {
        flattenedElements.push([null, e]);
      }
    })
  ));
  return flattenedElements;
}

async function downloadComparisonPolicies(
  policies: number[],
  setPolicyLoadingProgress: (progress: number) => void,
  dataDictionary: APIDictionary
): Promise<APIPolicy | null> {
  if (policies.length === 0) {
    // takes user out of comparison mode
    return null;
  }
  let policyIntersection: APIPolicy | null = null;
  setPolicyLoadingProgress(0);
  for (let i = 0; i < policies.length; i++) {
    const newPolicy = await getPolicy(policies[i]);
    if (policyIntersection === null) {
      policyIntersection = newPolicy;
    } else {
      policyIntersection = policyAnd(dataDictionary, policyIntersection, newPolicy);
    }
    setPolicyLoadingProgress((i + 1) / policies.length * 100);
  }
  setPolicyLoadingProgress(100);
  return policyIntersection as APIPolicy;
}

function isWithinRange(value: number, min: number, max: number) {
  if (min < max) {
    return value >= min && value <= max;
  } else {
    return value >= max && value <= min;
  }
}

function policyAnd(dict: APIDictionary, pa: APIPolicy, pb: APIPolicy): APIPolicy {
  const newRules = ([] as APIRule[]).concat(
    // for each element group ...
    ...dict.element_groups.map(eg => {
      // ... for each element, return a rule for that element
      return eg.elements.map(el => {
        const newRule: APIRule = { id: 0, element_id: el.id, attributes: [] };
        const paRule = getRuleForElement(pa, el.id);
        const pbRule = getRuleForElement(pb, el.id);
        // ... where the attributes are the bitwise AND of each rule's attributes
        if (!paRule && !pbRule) {
          // do nothing, use blank attributes list (all zeros)
        } else if (paRule && pbRule) {
          newRule.attributes = dict.rule_attributes.map(att => {
            const paAtt = getAttributeFromRule(paRule, att.id);
            const pbAtt = getAttributeFromRule(pbRule, att.id);
            // TODO: is the intersection of a value with a missing value 0?
            // Or is it the present value?
            /*
            if (paAtt && !pbAtt) { return paAtt; }
            if (!paAtt && pbAtt) { return pbAtt; }
            // intersection of two missing attribute values is 0
            if (!paAtt && !pbAtt) { return { attribute_id: att.id, value: 0 }; }
             */
            if (!paAtt || !pbAtt) {
              console.log("Short-cutting intersection for attribute " + att.id);
              return { attribute_id: att.id, value: 0 };
            }
            // do bitwise AND between the two values
            console.log(`E${el.id}:A${att.id} = ${paAtt.value} & ${pbAtt.value} = ${paAtt.value & pbAtt.value}`);
            return { attribute_id: att.id, value: paAtt.value & pbAtt.value };
          });
        }
        return newRule;
      });
    })
  );
  return Object.assign(pa, {
    // TODO: return an array so that we don't need to do a hacky .split() to
    // format the list of names
    name: pa.name + " ∩ " + pb.name,
    rules: newRules,
  });
}

function formatPolicyListItemName(policy: PolicyListItem) {
  return (
    <>
      <div className="org-type-badge">{ORG_TYPE_ABBR[policy.org_type]}</div>
      {policy.name + " (v" + policy.version + ")"}
    </>
  );
}

// update the value of a particular attribute in a policy, and also make new
// objects so that React knows to rerender that rule
function updatePolicyRuleAttributeValue(policy: APIPolicy, elementID: number, attributeID: number, newVal: number): APIPolicy {
  // TODO: do we need to change the top-level too
  policy = Object.assign({}, policy);
  policy.rules = policy.rules.slice();
  const ruleIndex = policy.rules.findIndex(r => r.element_id === elementID);
  let rule: APIRule;
  if (ruleIndex === -1) {
    rule = { id: 0, element_id: elementID, attributes: [] };
    policy.rules.push(rule);
  } else {
    rule = Object.assign({}, policy.rules[ruleIndex]);
    policy.rules[ruleIndex] = rule;
  }
  rule.attributes = rule.attributes.slice();
  const attributeIndex = rule.attributes.findIndex(a => a.attribute_id === attributeID);
  let attribute: APIRuleAttribute;
  if (attributeIndex === -1) {
    attribute = { attribute_id: attributeID, value: newVal };
    rule.attributes.push(attribute);
  } else {
    attribute = Object.assign({}, rule.attributes[attributeIndex]);
    rule.attributes[attributeIndex] = attribute;
  }
  attribute.value = newVal;
  //console.log("newPolicy", policy);
  return policy;
}

function updatePolicyRuleAttributes(policy: APIPolicy, elementID: number, newAttributes: APIRuleAttribute[]) {
  return updatePolicyRuleField(policy, elementID, "attributes", newAttributes);
}

function updatePolicyRuleField<T extends keyof APIRule>(policy: APIPolicy, elementID: number, fieldName: T, value: APIRule[T]) {
  policy = Object.assign({}, policy);
  policy.rules = policy.rules.slice();
  const ruleIndex = policy.rules.findIndex(r => r.element_id === elementID);
  let rule: APIRule;
  if (ruleIndex === -1) {
    rule = { id: 0, element_id: elementID, attributes: [] };
    policy.rules.push(rule);
  } else {
    rule = Object.assign({}, policy.rules[ruleIndex]);
    policy.rules[ruleIndex] = rule;
  }
  rule[fieldName] = value;
  return policy;
}

interface PolicyRuleProps {
  dictionary: APIDictionary;
  rule: APIRule;
  // undefined - we are not currently comparing policies
  // APIRule - we are comparing against this rule
  // null - we are comparing, but the target policy is missing this rule
  comparisonRule?: APIRule | null;
  element: APIElement;
  group?: { name: string; rows: number };
  selected: boolean;
  onSelectBegin?: () => void;
  onSelectEnd?: () => void;
  onSelectClear?: () => void;
  onCopyClick?: () => void;
  onPasteClick?: () => void;
  onChange: (elementID: number, attributeID: number, newVal: number) => void;
  onNoteChange: (newNote: string) => void;
}

// these need to be in sync with the values in the database
const RULE_ATTRIBUTES = {
  COLL: 1,
  VAL: 2,
  V3RQ: 3,
  SENS: 4,
  DG: 5,
  STORE: 6
};

const COLL_DONT_COLLECT = 2;
const VAL_V3 = 8;
const SENS_S0 = 1;

export class PolicyRule extends React.Component<PolicyRuleProps> {
  render() {
    const columns = this.props.dictionary.rule_attributes.map(att => (
      <PolicyRuleCell
        key={att.id}
        rule={this.props.rule}
        comparisonRule={this.props.comparisonRule}
        attribute={att}
        onChange={(newVal) => this.props.onChange(this.props.rule.element_id, att.id, newVal)}
      />
    ));
    let comparisonColumns: React.ReactNode[] | null = null;
    // if the comparison rule is null (missing), then use a blank rule so that
    // we render the default 0 value
    if (this.props.comparisonRule !== undefined) {
      const comparisonRule: APIRule = this.props.comparisonRule ?? {
        id: 0,
        element_id: this.props.rule.element_id,
        attributes: []
      };
      comparisonColumns = this.props.dictionary.rule_attributes.map(att => (
        <PolicyRuleCell
          rule={comparisonRule}
          attribute={att}
          onChange={(newVal) => null} // comparison is read-only
        />
      ));
    }
    return (
      <tr key={this.props.rule.id}>
        {this.props.group ? (
          <td className="element_group_name" rowSpan={this.props.group.rows}>
            <span>{this.props.group.name}</span>
          </td>
        ) : null}
        <td
          style={{
            backgroundColor: this.props.selected ? "lightblue" : "unset",
            userSelect: "none",
            cursor: "vertical-text",
          }}
          onMouseDown={(e) => this.props.onSelectBegin?.()}
          onMouseUp={(e) => this.props.onSelectEnd?.()}
          onMouseMove={(e) => {
            if (e.buttons === 1) {
              console.log("mouse move");
              this.props.onSelectEnd?.();
            }
          }}
        >{this.props.element.name}</td>
        {columns}
        <td>
          <Note
            value={this.props.rule.notes ?? null}
            onChange={(note) => this.props.onNoteChange(note)}
          />
        </td>
        {comparisonColumns ? (<td className="spacercol"></td>) : null}
        {comparisonColumns}
      </tr>
    );
  }
  // need this to prevent entire policy table from rendering when only one rule is changed
  shouldComponentUpdate(nextProps: PolicyRuleProps) {
    return !_.isEqual(this.props.rule, nextProps.rule) ||
      (this.props.comparisonRule !== nextProps.comparisonRule) ||
      (this.props.selected !== nextProps.selected);
  }
}

interface PolicyRuleCellProps {
  rule: APIRule;
  comparisonRule?: APIRule | null;
  attribute: APIAttribute;
  onChange: (newVal: number) => void;
}

// split this logic out of PolicyRule so that it's easier to reuse when
// displaying two policies side by side
function PolicyRuleCell(props: PolicyRuleCellProps) {
  const att = props.attribute;
  // if attribute value is missing, assume 0
  const ruleAtt = getAttributeFromRule(props.rule, att.id);
  const attValue = ruleAtt?.value ?? 0;
  let comparison: ComparisonResult = 'unknown';
  let comparisonValueDebug: number = 0;
  // undefined = we are not currently comparing against anything
  // null = comparing, but missing rule (assume 0)
  if (props.comparisonRule !== undefined) {
    let comparisonValue: number = 0;
    // comparisonRule might be null, in which case assume all 0
    if (props.comparisonRule) {
      const comparisonRuleAtt = getAttributeFromRule(props.comparisonRule, att.id);
      comparisonValue = comparisonRuleAtt?.value ?? 0;
    }
    if (attValue === comparisonValue) {
      comparison = 'equal';
    } else if ((attValue | comparisonValue) === comparisonValue) {
      // rule's value is a subset of the value of the intersection of the rules we're comparing against
      comparison = 'subset';
    } else if ((attValue | comparisonValue) === attValue) {
      comparison = 'superset';
    } else {
      comparison = 'incompatible';
    }
    comparisonValueDebug = comparisonValue;
  }
  const attOption = att.values.find(v => v.value === attValue);
  // if this attribute is not collected, then don't bother showing the values
  // of the other attributes
  if (att.id !== RULE_ATTRIBUTES.COLL) {
    const collAtt = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.COLL);
    if (collAtt && collAtt.value === COLL_DONT_COLLECT) {
      return (<td key={att.id} colSpan={1}></td>);
    }
  }
  if (att.id === RULE_ATTRIBUTES.V3RQ) {
    const valAtt = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.VAL);
    if (valAtt && valAtt.value === VAL_V3) {
      return (<td key={att.id} colSpan={1}></td>);
    }
  }
  if (att.id === RULE_ATTRIBUTES.DG) {
    const sensAtt = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.SENS);
    if (sensAtt && sensAtt.value === SENS_S0) {
      return (<td key={att.id} colSpan={1}></td>);
    }
  }
  return (
    <React.Fragment key={att.id}>
      <td>
        <AttributeSelect
          value={attValue}
          onChange={props.onChange}
          attribute={att}
        />
        {comparison !== 'unknown' ? comparisonToIcon(comparison) : null}
      </td>
    </React.Fragment>
  );
}

function comparisonToIcon(cr: ComparisonResult): React.ReactNode {
  switch (cr) {
    case "equal":
      /*
      Steve's spreadsheet shows no icon if they're equal
      */
      return null;
    case "subset":
      return (
        <span
          style={{ color: "green", verticalAlign: "middle" }}
          className="material-icons-outlined"
          title="subset"
        >chevron_left</span>
      );
    case "superset":
      return (
        <span
          style={{ color: "red", verticalAlign: "middle" }}
          className="material-icons-outlined"
          title="superset"
        >chevron_right</span>
      );
    case "incompatible":
      return (
        <span
          style={{ color: "red", verticalAlign: "middle" }}
          className="material-icons-outlined"
          title="incompatible disjoint set"
        >close</span>
      );
    default:
      return null;
  }
}

// make a XHR to /api/policies
async function getPolicy(policyID: number): Promise<APIPolicy> {
  const rawResponse = await fetch(`/api/policy/${policyID}`, {
    headers: getAPITokenHeaders()
  });
  const response = await rawResponse.json() as unknown as GetPolicy;
  if (response.status === 'success') {
    return response.policy;
  } else {
    throw new Error(response.error);
  }
}

// post policy JSON to /api/policy/:policyID
function savePolicy(policy: APIPolicy): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const headers = getAPITokenHeaders();
    headers.set('Content-Type', 'application/json');
    fetch(`/api/policy/${policy.id}`, {
      method: 'POST',
      headers: headers,
      // don't send the whole list of TLDs, just the ID of the list
      body: JSON.stringify(_.omit(policy, ['tlds']))
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as APISuccess | APIError;
        if (response.status === 'success') {
          return resolve(true);
        } else {
          return reject(false);
        }
      })
      .catch(error => reject(error));
  });
}

async function copyPolicy(policyID: number): Promise<number> {
  const rawResponse = await fetch(`/api/policy/copy/${policyID}`,
    {
      method: 'POST',
      headers: getAPITokenHeaders()
    });
  const response = await rawResponse.json() as unknown as NewPolicy | APIError;
  if (response.status === 'success') {
    return response.id;
  } else {
    throw new Error(response.error);
  }
}

async function deletePolicy(policyID: number): Promise<boolean> {
  const rawResponse = await fetch(`/api/policy/${policyID}`,
    {
      method: 'DELETE',
      headers: getAPITokenHeaders()
    });
  const response = await rawResponse.json() as unknown as { status: "success" } | APIError;
  if (response.status === 'success') {
    return true;
  } else {
    throw new Error(response.error);
  }
}

async function newPolicyVersion(policyID: number): Promise<number> {
  const rawResponse = await fetch(`/api/policy/new_version/${policyID}`,
    {
      method: 'POST',
      headers: getAPITokenHeaders(),
    });
  const response = await rawResponse.json() as unknown as NewPolicy | APIError;
  if (response.status === 'success') {
    return response.id;
  } else {
    throw new Error(response.error);
  }
}

// given a url, return the hash part of it not including the "#" character, or "" if there is no hash
function getURLHash(url: string): string {
  if (url.indexOf('#')) {
    return url.substring(
      url.indexOf('#') + 1
    );
  } else {
    return "";
  }
}

function mapPolicyIDsToName(policies: PolicyListItem[]) {
  const mapping: { [key: number]: PolicyListItem } = {};
  policies.forEach(p => mapping[p.id] = p);
  return mapping;
}

// given an orig list and an overlay list, return a new list that is orig
// overwritten with the selectedAtts from overlay. Used for pasting specific
// columns.
export function mergeAttributes(orig: APIRuleAttribute[], overlay: APIRuleAttribute[], selectedAtts: number[]) {
  return orig.filter(att => !selectedAtts.includes(att.attribute_id))
    .concat(overlay.filter(att => selectedAtts.includes(att.attribute_id)));
}
