import React from 'react';

interface CollapsibleBoxProps {
  style?: React.CSSProperties;
  title: React.ReactNode;
  children: React.ReactNode;
}

export default function CollapsibleBox(props: CollapsibleBoxProps) {
  const [collapsed, setCollapsed] = React.useState(false);

  const DEFAULT_STYLES: React.CSSProperties = {
    border: '1px solid black',
  };

  return (
    <div style={Object.assign(DEFAULT_STYLES, props.style)}>
      <h3 className="collapsible-box-title"
        style={{
          cursor: 'pointer',
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className="material-icons-outlined"
          title={ collapsed ? "Collapsed" : "Expanded" }
          style={{ verticalAlign: "middle" }}
        >
          { collapsed ? "expand_more" : "expand_less" }
        </span>
        {props.title}
      </h3>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        {props.children}
      </div>
    </div>
  );
}
