import React from 'react';
import ReactModal from 'react-modal';
import AttributeSelect from './AttributeSelect';

import { APIDictionary, APIRuleAttribute, APITLDList } from "./common/api";
import TLDListSelector from './TLDListSelector';


interface ScopeProps {
  dataDictionary: APIDictionary;
  scopeAttributes: APIRuleAttribute[];
  tldList: APITLDList | null;
  onAttributeChange: (attributeID: number, newValue: number) => void;
  onTLDListChange?: (tldList: APITLDList) => void;
}

export default function Scope(props: ScopeProps) {
  const [isSelectingTLDList, setIsSelectingTLDList] = React.useState(false);

  const attributeRows = props.dataDictionary.scope_attributes.map((att) => {
    const attValue = props.scopeAttributes.find(sa => sa.attribute_id === att.id);
    return (
      <tr key={att.id}>
        <td>{att.name}</td>
        <td>
          <AttributeSelect
            attribute={att}
            value={attValue?.value ?? 0}
            onChange={(newVal) => props.onAttributeChange(att.id, newVal)}
          />
        </td>
      </tr>
    );
  }
  );
  return (
    <table
      // we'll leave it up to the container to constrain the width of this table
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <tbody>
        <tr>
          <td>PSLs</td>
          <td>
            <button onClick={() => setIsSelectingTLDList(true)}
              style={{ float: "right" }}>
              Choose...
            </button>
            {
              props.tldList && props.tldList.tlds.length > 0 ?
                (
                  <>
                    <strong>{props.tldList.name}</strong>
                    <br />
                    <ElidedList list={props.tldList.tlds} />
                  </>
                ) :
                <em>None</em>
            }
            <ReactModal
              isOpen={isSelectingTLDList}
              onRequestClose={() => setIsSelectingTLDList(false)}
            >
              <TLDListSelector
                value={props.tldList?.id ?? null}
                onSelect={(newTLDList) => {
                  props.onTLDListChange?.(newTLDList);
                  setIsSelectingTLDList(false);
                }}
              />
            </ReactModal>
          </td>
        </tr>
        {attributeRows}
      </tbody>
    </table>
  );
}

interface ElidedListProps {
  list: string[];
  maxItems?: number;
}

function ElidedList(props: ElidedListProps): React.ReactElement | null {
  const [ collapsed, setCollapsed ] = React.useState(true);

  const maxItems = props.maxItems || 10;

  if (props.list.length > maxItems) {
    if (collapsed) {
    const subList = props.list.slice(0, maxItems);
      return (
        <>
          {subList.join(", ")},&nbsp;
          <a href="#"
            style={{ fontStyle: "italic" }}
            onClick={(e) => {
              e.preventDefault();
              setCollapsed(false);
            }}>... ({props.list.length - maxItems} more)</a>
        </>
      );
    } else {
      return (
        <>
          {props.list.join(", ")}&nbsp;
          <a href="#"
            style={{ fontStyle: "italic" }}
            onClick={(e) => {
              e.preventDefault();
              setCollapsed(true);
            }}>(less)</a>
        </>
      );
    }
  } else {
    return <>{props.list.join(", ")}</>;
  }
}
