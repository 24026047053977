// Given a background color in hex form (e.g. "FF00FF"),
// choose the foreground color with the highest contrast

// cache the results
const calculatedColors: { [key: string]: "white" | "black" } = {}

export function contrastColor(input: string): "white" | "black" {
  if (calculatedColors.hasOwnProperty(input)) {
    return calculatedColors[input];
  }
  try {
    let [r, g, b] = parseHexColor(input);
    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma > 127 ? "black" : "white";
  } catch (e) {
    // default to black in case of invalid color
    console.log(e);
    return "black";
  }
}

function parseHexColor(input: string): [number, number, number] {
  let m = input.match(/^#?([0-9a-f]{3})$/i)?.[1];
  if (m) {
    // in three-character format, each value is multiplied by 0x11 to give an
    // even scale from 0x00 to 0xff
    return [
      parseInt(m.charAt(0), 16) * 0x11,
      parseInt(m.charAt(1), 16) * 0x11,
      parseInt(m.charAt(2), 16) * 0x11
    ];
  }

  m = input.match(/^#?([0-9a-f]{6})$/i)?.[1];
  if (m) {
    return [
      parseInt(m.substr(0, 2), 16),
      parseInt(m.substr(2, 2), 16),
      parseInt(m.substr(4, 2), 16)
    ];
  }
  throw new Error(`Invalid hex color: '${input}'`);
}


// backoff algorithm for retrying http requests
let backoffCounter: { [key: string]: number } = {};

// Call this function before each HTTP request to limit the rate of that request
// need to provide a unique string that distinguishes between different requests
// that are not retries of the same thing.
// Once the request is successful, call resetRetries() to reset it.
// TODO: make this take a function to be retried.
export async function retryLimiter(key: string, max: number): Promise<void> {
  let currCount;
  if (backoffCounter.hasOwnProperty(key)) {
    backoffCounter[key]++;
    if (backoffCounter[key] > max) {
      throw new Error("Maximum retry limit exceeded for key '" + key + "'");
    }
    currCount = backoffCounter[key];
  } else {
    currCount = backoffCounter[key] = 1;
  }
  // wait if this isn't our first try
  if (currCount > 1) {
    await delay(300 * (2 ^ (currCount - 1)));
  }
}

export function resetRetries(): void {
  backoffCounter = {};
}

function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
