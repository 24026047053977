import React from 'react';
import Select from 'react-select';
import { APIAttribute } from './common/api';
import { contrastColor } from './webutils';

interface AttributeSelectProps {
  attribute: APIAttribute;
  value: number;
  onChange: (newValue: number) => void;
}

export default function AttributeSelect(props: AttributeSelectProps) {
  const att = props.attribute;
  const attOption = att.values.find(v => v.value === props.value);

  return (
    <>
    <span className="media-print attribute-value" style={{
      backgroundColor: "#" + attOption?.color,
      color: attOption ? contrastColor(attOption.color) : 'red',
    }}>{attOption?.name}</span>
    <Select<{ label: string, value: number, color: string }>
    className="rule-cell-select media-screen"
    styles={{
      //menu: (base) => ({ ...base, width: "max-content", minWidth: "100%" }),
      container: (provided) => ({
        ...provided,
        width: "115px",
        verticalAlign: "middle",
        display: "inline-block",
      }),
      // this style on indicatorsContainer plus unsetting control's minHeight helps shrink the size of the select drop-downs
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "3px",
      }),
      control: (provided) => ({
        ...provided,
        backgroundColor: "#" + attOption?.color,
        minHeight: "unset",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: attOption ? contrastColor(attOption.color) : 'red',
      }),
      menu: (provided) => ({
        ...provided,
        boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.8)",
      }),
      option: (provided, props) =>
      ({
        ...provided,
        backgroundColor: "#" + props.data.color,
        color: contrastColor(props.data.color),
        outline: props.isFocused ? "2px solid purple" : undefined,
        outlineOffset: "-1.5px",
      })
    }}
    value={{ value: props.value, label: attOption?.name ?? "", color: attOption?.color ?? "ffffff" }}
    onChange={(newVal) => newVal ? props.onChange(newVal.value) : null}
    options={
      att.values.map(opt => ({ value: opt.value, label: opt.name, color: opt.color ?? "ffffff" }))
    } />
    </>
  );
}
