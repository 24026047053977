import React from 'react';
import { PolicyListItem } from './common/api';
import { ORG_TYPE_ABBR } from './common/util';
import LoadingSpinner from './LoadingSpinner';
import { getPolicyList } from './policyData';


interface MultiSelectPolicyListProps {
  onSelectPolicy: (policy: PolicyListItem) => void;
  selectedPolicies?: number[];
}

export default function MultiSelectPolicyList(props: MultiSelectPolicyListProps) {

  const [policyList, setMultiSelectPolicyList] = React.useState<PolicyListItem[] | null>(null);
  const [isLoadingList, setIsLoadingList] = React.useState<boolean>(false);

  if (policyList === null && !isLoadingList) {
    setIsLoadingList(true);
    getPolicyList().then(setMultiSelectPolicyList).finally(() => setIsLoadingList(false));
  }

  if (policyList === null) {
    return (<LoadingSpinner />);
  }

  return (
    <div className="policy-list">
      <table style={{ width: "100%" }} className="policy-list-table">
        <tbody>
          {
            policyList.map(policy => (
              <tr key={policy.id}
                onClick={() => props.onSelectPolicy(policy)}
                style={{cursor: "pointer"}}
              >
                {
                  props.selectedPolicies ? (
                    <td>
                      <input type="checkbox" checked={props.selectedPolicies.includes(policy.id)} readOnly />
                    </td>
                  ) : null
                }
                <td>
                  <span className="org-type-badge">{ORG_TYPE_ABBR[policy.org_type]}</span>
                  {policy.name} (v{policy.version})
                </td>
                <td style={{ textAlign: "right" }}>
                  Edited {timeDifference(Date.now() / 1000, policy.modified)}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

// given two unix timestamps, return the time difference as a human-readable string
function timeDifference(current: number, previous: number) {
  const sPerMinute = 60;
  const sPerHour = sPerMinute * 60;
  const sPerDay = sPerHour * 24;
  const sPerMonth = sPerDay * 30;
  const sPerYear = sPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < sPerMinute) {
    return Math.round(elapsed) + ' seconds ago';
  }

  else if (elapsed < sPerHour) {
    return Math.round(elapsed / sPerMinute) + ' minutes ago';
  }

  else if (elapsed < sPerDay) {
    return Math.round(elapsed / sPerHour) + ' hours ago';
  }

  else if (elapsed < sPerMonth) {
    return 'approximately ' + Math.round(elapsed / sPerDay) + ' days ago';
  }

  else if (elapsed < sPerYear) {
    return 'approximately ' + Math.round(elapsed / sPerMonth) + ' months ago';
  }

  else {
    return 'approximately ' + Math.round(elapsed / sPerYear) + ' years ago';
  }
}
