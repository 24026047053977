import React from 'react';
import { GetPolicyList, PolicyListItem } from './common/api';
import { ORG_TYPE_ABBR } from './common/util';
import LoadingSpinner from './LoadingSpinner';
import { getAPITokenHeaders } from './password';
import { getPolicyList } from './policyData';


interface PolicyListProps {
  onSelectPolicy: (newPolicyID: number) => void;
}

export default function PolicyList(props: PolicyListProps) {

  const [policyList, setPolicyList] = React.useState<PolicyListItem[] | null>(null);
  const [isLoadingList, setIsLoadingList] = React.useState<boolean>(false);

  if (policyList === null && !isLoadingList) {
    setIsLoadingList(true);
    getPolicyList().then(setPolicyList).finally(() => setIsLoadingList(false));
  }

  if (policyList === null) {
    return (<LoadingSpinner />);
  }

  return (
    <div className="policy-list">
      <table style={{ width: "100%" }} className="policy-list-table">
        <tbody>
          {
            policyList.map(policy => (
              <tr key={policy.id}
                onClick={() => props.onSelectPolicy(policy.id)}
              >
                <td>
                  <a href={`#/policy/${policy.id}`}
                    onClick={() => props.onSelectPolicy(policy.id)}
                  >
                    <span className="org-type-badge">{ORG_TYPE_ABBR[policy.org_type]}</span>
                    {policy.name}
                  </a>
                  <br />
                  {policy.prime_poc !== '' ? policy.prime_poc + ", " : null}{policy.org_name}
                </td>
                <td style={{ textAlign: "right" }}>
                  Version {policy.version}
                  <br />
                  <small>(Edited {timeDifference(Date.now() / 1000, policy.modified)})</small>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

// given two unix timestamps, return the time difference as a human-readable string
function timeDifference(current: number, previous: number) {
  const sPerMinute = 60;
  const sPerHour = sPerMinute * 60;
  const sPerDay = sPerHour * 24;
  const sPerMonth = sPerDay * 30;
  const sPerYear = sPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < sPerMinute) {
    return Math.round(elapsed) + ' seconds ago';
  }

  else if (elapsed < sPerHour) {
    return Math.round(elapsed / sPerMinute) + ' minutes ago';
  }

  else if (elapsed < sPerDay) {
    return Math.round(elapsed / sPerHour) + ' hours ago';
  }

  else if (elapsed < sPerMonth) {
    return Math.round(elapsed / sPerDay) + ' days ago';
  }

  else if (elapsed < sPerYear) {
    return Math.round(elapsed / sPerMonth) + ' months ago';
  }

  else {
    return Math.round(elapsed / sPerYear) + ' years ago';
  }
}
