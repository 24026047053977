import { APIPolicy, APIRule, APIRuleAttribute } from "./api";

export function getRuleForElement(policy: APIPolicy, elementID: number): APIRule | undefined {
  return policy.rules.find(r => r.element_id === elementID);
}

export function getAttributeFromRule(rule: APIRule, attributeID: number): APIRuleAttribute | undefined {
  return rule.attributes.find(a => a.attribute_id === attributeID);
}


export const ORG_TYPE_ABBR: { [key in APIPolicy['org_type']]: string } = {
  "policy_authority": "PA",
  "registrar": "Rr",
  "registry": "Ry",
};
