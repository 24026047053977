import _ from 'lodash';
import React from 'react';
import { APIDictionary, APIRegistrantFactItem, APIRegistrantFacts, GetRegistrantFacts } from './common/api';
import LoadingSpinner from './LoadingSpinner';
import { getAPITokenHeaders } from './password';

interface RegistrantFactsEditorProps {
  selectedRegistrantFactsID: number | null;
  dataDictionary: APIDictionary;
}

export default function RegistrantFactsEditor(props: RegistrantFactsEditorProps) {
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ registrantFacts, setRegistrantFacts ] = React.useState<APIRegistrantFacts | null>(null);

  if (isLoading) {
    return (<LoadingSpinner />);
  }

  if (!registrantFacts || (
      registrantFacts.id !== props.selectedRegistrantFactsID && registrantFacts.id !== 0 && props.selectedRegistrantFactsID !== null
    )) {
    if (props.selectedRegistrantFactsID) {
      console.log("Initiating load of registrant facts");
      setIsLoading(true);
      // TODO: handle error case (shouldn't endlessly retry
      getRegistrantFacts(props.selectedRegistrantFactsID).then(setRegistrantFacts).finally(() => setIsLoading(false));
      return (<LoadingSpinner />);
    } else {
      console.log("Setting registrant facts to empty", registrantFacts);
      setRegistrantFacts({
        id: 0,
        name: "Untitled Registrant Facts Set",
        facts: []
      });
    }
    return null;
  }

  return (
    <div className="RegistrantFactsEditor">
      <h2>{
        props.selectedRegistrantFactsID === null ?
        "Create new set of registrant facts" :
        registrantFacts.name
      }</h2>
      <button onClick={() => console.log(registrantFacts)}>Save</button>
      <table>
        <thead>
          <th>Category</th>
          <th>Element</th>
          <th>Value</th>
        </thead>
        <tbody>
          {
            props.dataDictionary.element_groups.map(eg => (
              eg.elements.map((el, i) => {
                const regFact = registrantFacts.facts.find(rf => rf.element_id === el.id);
                let groupCell: React.ReactNode = null;
                if (i === 0) {
                  groupCell = (
                    <td rowSpan={eg.elements.length}>{eg.name}</td>
                  );
                }
                return (
                  <tr>
                    {groupCell}
                    <td>{el.name}</td>
                    <td>
                      <input type="text" value={regFact?.value || ""}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // empty string removes the value
                          if (newValue.trim() === '') {
                            return setRegistrantFacts(
                              Object.assign({},
                                registrantFacts,
                                { facts: registrantFacts.facts.filter(rf => rf.element_id !== el.id) }
                              )
                            );
                          } else {
                            const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
                            const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
                            newFactObject.facts = newFactObject.facts.slice();
                            // if modifying an existing fact, update it
                            if (existingFactIndex !== -1) {
                              const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { value: newValue });
                              newFactObject.facts[existingFactIndex] = newFact;
                            } else { // otherwise, add it to the set of facts
                              const newFact: APIRegistrantFactItem = {
                                element_id: el.id,
                                value: newValue
                              };
                              newFactObject.facts.push(newFact);
                            }
                            setRegistrantFacts(newFactObject);
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              }
            )))
          }
        </tbody>
      </table>
    </div>
  );
}


function getRegistrantFacts(rfID: number): Promise<APIRegistrantFacts> {
  return new Promise((resolve, reject) => {
    fetch(`/api/registrant_facts/${rfID}`, {
      headers: getAPITokenHeaders()
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as GetRegistrantFacts;
        if (response.status === 'success') {
          return resolve(response.registrant_facts);
        } else {
          return reject(response.error);
        }
      })
      .catch(error => reject(error));
  });
}
