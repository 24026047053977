import { APIPolicy, GetPolicyList, PolicyListItem } from "./common/api";
import { getAPITokenHeaders } from "./password";

let policyList: PolicyListItem[] | null = null;
let policyListFetched: number = 0; // unix timestamp milliseconds

export async function getPolicyList(): Promise<PolicyListItem[]> {
  // TODO: enable caching if needed
  // 5 minute cache time
  //if (policyList === null || Date.now() - policyListFetched > 1000 * 60 * 5) {
    policyList = await getPolicyList_uncached();
    policyListFetched = Date.now();
  //}
  return policyList;
}

export async function getPolicyMetadata(policies: number[]) {
  const rawResponse = await fetch(`/api/policies?policy=${policies.join(",")}`,
    {
      headers: getAPITokenHeaders()
    }
  );
  const response = await rawResponse.json() as unknown as GetPolicyList;
  if (response.status === 'success') {
    return response.policies;
  }
  else {
    throw new Error(response.error);
  }
}

export function clearPolicyListCache() {
  policyList = null;
  policyListFetched = 0;
}

function getPolicyList_uncached(): Promise<PolicyListItem[]> {
  return new Promise((resolve, reject) => {
    fetch('/api/policies', {
      headers: getAPITokenHeaders()
    })
      .then(async rawResponse => {
        const response = await rawResponse.json() as unknown as GetPolicyList
        if (response.status === 'success') {
          sortPolicyList(response.policies)
          return resolve(response.policies);
        } else {
          return reject(response.error);
        }
      })
      .catch(error => reject(error));
  });
}

export const ORG_TYPE_ORDERING: { [key in APIPolicy['org_type']]: number } = {
  "policy_authority": 1,
  "registrar": 3,
  "registry": 2,
};

function sortPolicyList(policyList: PolicyListItem[]) {
  policyList.sort((a, b) =>
    a.org_type == b.org_type ?
      a.name.localeCompare(b.name) :
      ORG_TYPE_ORDERING[a.org_type] -
      ORG_TYPE_ORDERING[b.org_type]);
}
