import React from "react";

interface LoadingSpinnerProps {
  height?: React.CSSProperties["height"];
}


export default function LoadingSpinner(props: LoadingSpinnerProps) {

  const dots = [1, 2, 3].map(i => (
    <div key={i} style={{
      boxSizing: "border-box",
      display: "block",
      position: "absolute",
      width: "64px",
      height: "64px",
      margin: "8px",
      border: "8px solid #05f",
      borderColor: "#05f transparent transparent transparent",
      borderRadius: "50%",
      animation: "lds-ring 1.2s cubic-bezier(0.5, 0.0, 0.5, 1) infinite",
      animationDelay: `-${i * 0.15}s`,
    }} />
  ));

  return (
    <div style={{
      display: "block",
      width: "100%",
      height: props.height ?? "200px",
      textAlign: "center",
    }}
      className="LoadingSpinner"
    >
      <div style={{
        display: "inline-block",
        position: "relative",
        width: "80px",
        height: "80px",
      }}>{dots}</div>
      <style>{`
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      `}</style>
    </div>
  );
}
